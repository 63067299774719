import React from 'react';

import Nav from './nav';

const header = () => {
    return (
        <div>
            <Nav />
        </div>
    );
};

export default header;