export const photos = [
    {
      src: "/img/gallery/h1.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/i8.jpg",
      width: 1,
      height: 1
    },
    {
      src: "/img/gallery/v1.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/gallery/i2.jpg",
      width: 1,
      height: 1
    },
    {
      src: "/img/gallery/i3.jpg",
      width: 1,
      height: 1
    },
    {
      src: "/img/gallery/v5.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/gallery/i4.jpg",
      width: 1,
      height: 1
    },
    {
      src: "/img/gallery/v2.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/gallery/i1.jpg",
      width: 1,
      height: 1
    },
    {
      src: "/img/gallery/i5.jpg",
      width: 1,
      height: 1
    },
    {
      src: "/img/gallery/i11.jpg",
      width: 1,
      height: 1
    },
    {
      src: "/img/gallery/h11.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/gallery/i6.jpg",
      width: 1,
      height: 1
    },
    {
      src: "/img/gallery/i7.jpg",
      width: 1,
      height: 1
    },
    {
      src: "/img/gallery/v3.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/gallery/i12.jpg",
      width: 1,
      height: 1
    },

  ];
  